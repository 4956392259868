<template>
  <div>
    <div class="title">{{ $t("adnavPage.repaymentManage") }}</div>
    <el-form class="flex-row" ref="form" :model="form" style="width: 1050px">
      <el-form-item class="flex-row project-view" :label="$t('projectNumber')">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item class="search-date" :label="$t('repaymentDate')">
        <el-form-item prop="date1">
          <el-date-picker
            type="date"
            :placeholder="$t('selectDate')"
            v-model="form.date1"
          ></el-date-picker>
        </el-form-item>
        <span>&nbsp;{{ $t("to") }}&nbsp;</span>
        <el-form-item prop="date2">
          <el-date-picker
            type="date"
            :placeholder="$t('selectDate')"
            v-model="form.date2"
          ></el-date-picker>
        </el-form-item>
      </el-form-item>
      <el-button class="btn-black" style="margin-bottom: 22px">{{
        $t("search")
      }}</el-button>
    </el-form>
    <div class="btn-list" style="margin-bottom: 20px">
      <el-button
        :class="item.select ? 'btn-black' : 'btn-gray'"
        v-for="(item, index) in btnList"
        :key="index"
        @click="typeClick(item, index)"
        >{{ $t(item.title) }}</el-button
      >
    </div>
    <el-table :data="tableData" style="width: 1200px">
      <template slot="empty">
        <div v-loading="tableLoading" v-text="tableEmpty"></div>
      </template>
      <el-table-column
        prop="investmentStatus"
        :label="$t('repaymentStage')"
        width="140"
        align="center"
        key="1"
      >
        <template v-slot="scope">
          <span>{{ scope.row | financingStat }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="id"
        :label="$t('projectNumberAgain')"
        width="130"
        align="center"
        key="3"
      ></el-table-column>
      <el-table-column
        prop="financingType"
        :label="$t('financingType')"
        width="120"
        align="center"
        key="4"
      ></el-table-column>
      <el-table-column
        prop="askAmount"
        :label="$t('financingAmountAgain')"
        width="100"
        align="center"
        key="5"
      ></el-table-column>
      <el-table-column
        prop="terms"
        :label="$t('numberOfPeriods')"
        width="90"
        align="center"
        key="6"
      ></el-table-column>
      <el-table-column
        prop="date"
        :label="$t('deadlineForRepayment')"
        width="120"
        align="center"
        key="7"
      ></el-table-column>
      <el-table-column
        prop="interest"
        :label="$t('interest')"
        width="120"
        align="center"
        key="8"
      ></el-table-column>
      <el-table-column
        prop="repayAmount"
        :label="$t('currentPeriod')"
        width="120"
        align="center"
        key="9"
      ></el-table-column>
      <el-table-column
        v-if="btnList[1].select"
        prop="updateDate"
        :label="$t('repaymentTime')"
        width="120"
        align="center"
        key="10"
      ></el-table-column>
      <el-table-column
        fixed="right"
        :label="$t('operation')"
        min-width="100"
        align="center"
      >
        <template v-slot="scope">
          <el-button
            v-if="btnList[0].select"
            size="small"
            class="btn-black"
            @click="paypopShow(scope.$index, scope.row)"
            >{{ $t("repayment") }}</el-button
          >
          <el-button
            size="small"
            class="btn-black"
            @click="handleDelete(scope.$index, scope.row)"
            >{{ $t("see") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :visible.sync="payPop"
      width="660px"
      :before-close="handleClose"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <div class="flex-column-center pop-info">
        <div class="flex-between title-blue">
          <div></div>
          <img src="../../static/img/colse-white.png" @click="payPop = false" />
        </div>
        <img src="../../static/img/bells.png" />
        <!-- <div class="time-before">{{$t('pleaseComeTo')}}{{dialogObj.date}}{{$t('before')}}</div> -->
        <div class="time-before">
          {{ $t("theCurrentState") }}: {{ $t("inThePayment") }}
        </div>
        <div class="bank">
          <div>
            <span>{{ $t("theAccountBalance") }} :</span>
            {{ userAccountAmount }} ({{ $t("millionDollars") }})
          </div>
          <div>
            <span>{{ $t("currentPayment") }} :</span>
            {{ dialogObj.amount }} ({{ $t("millionDollars") }})
          </div>
        </div>
        <div>
          <el-button class="btn" @click="repay" :disabled="isSubmit">{{
            $t("confirmPayment")
          }}</el-button>
          <el-button class="cancel" @click="payPop = false" :disabled="isSubmit">{{
            $t("btnI18n.Cancel")
          }}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { connect, getFinanceRequests, getMyBalance } from '@/utils/blockchain';
import { apiGetFinanceRequests, apiGetMyBalanceBignum, apiGetMyBalance } from "@/utils/api";
import {differenceInDays} from "date-fns";
import BigNumber from "bignumber.js";

export default {
  data() {
    return {
      isSubmit: false,
      form: {
        name: undefined
      },
      tableData: [],
      tableLoading: false,
      tableEmpty: this.$t('loading'),
      btnList: [{ title: 'toBeRepaid', select: true }, { title: 'financRepaid', select: false }],
      payPop: false,
      repayId: null,
      // 弹窗对象
      dialogObj: {
        amount: '',
        data: ''
      },
      userAccountAmount: 0,
      toggleIndex: 0
    };
  },
  mounted() {
    this.tableLoading = true;
  },
  methods: {
    handleDelete(index, rows) {
      sessionStorage.setItem("repaymentDetail", JSON.stringify(rows));
      this.$router.push("/repayment-list/repayment-detail");
    },
    async typeClick(item, index) {
      this.btnList.forEach(item => {
        item.select = false;
      });
      this.btnList[index].select = true;
      if (index === 0) {
        this.toggleIndex = 0;
      } else {
        this.toggleIndex = 1;
      }
      await this.refreshData();
    },
    handleClose(done) {
      this.payPop = false;
    },
    paypopShow(index, row) {
      this.dialogObj = {
        amount: row.repayAmount,
        date: row.date
      };
      this.repayId = row.id;
      this.payPop = true;

    },
    async repay(index, row) {
      this.isSubmit = true;
      try {
        let balance = await apiGetMyBalanceBignum();
        if (balance.gte(this.dialogObj.amount)) {
          await tradeFlowContract.methods.repay(this.repayId).send({ from: (await (await web3.eth.getAccounts())[0]) });
          this.payPop = false;
          await this.refreshData();
        } else {
          this.$message.error(this.$t('InsufficientBalance'));
          this.payPop = false;
        }
        this.isSubmit = false;
      } catch (error) {
        this.isSubmit = false;
      }
    },
    formatStatus(financingType) {
      let t = '';
      switch (financingType) {
        case 'Funded':
          t = this.$t('repaymentInProgress');
          break;
        default:
          t = financingType;
      }
      return t;
    },

    async refreshData() {
      let address = (await web3.eth.getAccounts())[0];
      this.userAccountAmount = await apiGetMyBalance();
      this.tableData = [];
      this.tableLoading = true;
      const MILLISECONDS_PER_DAY = 86400 * 1000;
      let financeRequests = await apiGetFinanceRequests();
      financeRequests = financeRequests.reverse();
      this.tableData = financeRequests
        .filter((data) => {
          return (data.statusId === "1" && data.fundsSent && data.repaid === (this.toggleIndex === 1)) &&
              data.supplierAddress === address;
        })
        .map(data => {
          let deadlineDateTime = new Date(this.formatTime(new Date(parseInt(data.manufacturingStartDate) - (7 * MILLISECONDS_PER_DAY)))).getTime();
          let nowDateTime = new Date(this.formatTime(new Date())).getTime();
          return {
            approved: data.approved,
            statusId: data.statusId,
            status: data.status,
            extraData: data.extraData,
            account: data.account,
            user: data.user,
            bank: data.bank,
            method: this.$t('equalPrincipal'),
            apr: data.aprStr,
            blockNumber: data.blockNumber,
            brandOwner: data.brandOwner,
            brandOwnerAddress: data.brandOwnerAddress,
            buyer: data.buyer,
            description: data.description,
            fundingSum: data.fundingSumStr,
            fundsSent: data.fundsSent,
            length: data.length,
            manufacturingStartDate: data.manufacturingStartDateStr,
            purpose: data.purpose,
            repaid: data.repaid,
            supplier: data.supplier,
            supplierAddress: data.supplierAddress,
            name: data.supplier,
            id: data.id,
            financingType: this.$t('accountsReceivableShort'),
            askAmount: data.askAmountStr,
            type1: data.status,
            interest: data.interest,
            investmentStatus: this.formatStatus(data.investmentStatus),
            deadlineDate: data.deadlineDate,
            date: data.endDate,
            updateDate: this.formatTime(new Date()),
            repayAmount: data.repayAmountStr,
            days: `${data.length} ${this.$t('day')}`,
            annualizedIncome: `${data.apr}%`,
            price: data.repayAmountStr,
            term: data.length,
            terms: "1-1",
            interestRate: data.aprStr,
            fundsProvided: data.fundsProvided,
            endDate: data.manufacturingStartDateStr,
            financingDiscount: data.investmentProgress,
            repayment: data.endDate,
            remainingDays: data.remainingDays,
            toInvestAmount: data.toInvestAmount, // 募资金额
            investmentProgress: data.investmentProgress,
            payReceived: data.manufacturingStartDateStr,
            day: data.length
          };
        });
      this.tableLoading = false;
      this.tableEmpty = this.$t('tableEmpty');
    }
  },
  async beforeCreate() {
    await connect();
    this.refreshData();
  },
};
</script>

<style lang="scss" scoped>
.info-view {
  flex-wrap: wrap;
  .info-item {
    width: 24%;
    line-height: 30px;
  }
}
.el-table th {
  background: #81d8d0;
  color: white;
}
.el-form-item__label {
  margin-left: 20px;
}
.project-view {
  width: 350px;
}
.search-date {
  width: 600px;
  margin-left: 50px;
  .el-form-item,
  .el-form-item__label,
  .el-form-item__content {
    display: inline-block;
  }
  .el-input,
  .el-input__inner {
    width: 200px;
  }
}
.el-input,
.el-input__inner {
  width: 200px;
}
.pop-info {
  font-size: 18px;
  line-height: 26px;
  position: relative;
  color: #353535;
  width: 360px;
  margin: 0 auto;
  word-break: normal;
  .title-blue {
    width: 660px;
    height: 50px;
    background: #81d8d0;
    position: absolute;
    top: -80px;
    img {
      width: 30px;
      height: 30px;
      margin-right: 20px;
    }
  }
  .time-before {
    font-size: 22px;
    font-weight: bold;
    line-height: 50px;
  }
  .bank {
    font-size: 16px;
    line-height: 20px;
    // margin-left: -20px;
    margin: 14px 0px 30px -80px;
    span {
      width: 200px;
      display: inline-block;
      text-align: right;
    }
  }
  .account {
    text-align: center;
  }
  .btn {
    border-radius: 0;
    background: #353535;
    color: white;
    width: 130px;
  }
  .cancel {
    border-radius: 0;
    background: #fff;
    color: #353535;
    width: 130px;
  }
}
</style>
