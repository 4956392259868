import tradeFlowJson from '../data/TradeFlow.json';
import Web3 from 'web3';
import { lowerCase } from 'lodash';
import { cachedRequest } from './eth-cache';
import {
  connect,
  getUser,
  getUsers,
  resolveRoleId,
  getSupplierOverview,
  getFinanceRequests,
  getExchangeRequests,
} from "./blockchain";
import axios from '@/plugins/axios';
import { differenceInDays, format } from "date-fns";
import * as numberUtil from "echarts/lib/util/number";
import BigNumber from "bignumber.js";
import enums from "@/utils/enums";
const UNIT_MILLION = enums.UNIT_MILLION;

// 公共 start
// 获取连接
export async function apiConnect() {
  await connect();
}
// 登录/注册
export async function apiLogin() {
}
// 获取用户信息
export async function apiGetUser(address) {
  return await getUser(address);
}
// 获取所有用户列表信息
export async function apiGetUsers() {
  return await getUsers();
}
// 获取用户资金
export async function apiGetMyBalanceNumber() {
  let address = (await web3.eth.getAccounts())[0];
  let balance = await tradeFlowContract.methods.balanceOf(address).call();
  return Number(web3.utils.fromWei(balance)).toFixed(2);
}
export async function apiGetMyBalanceBignum() {
  let address = (await web3.eth.getAccounts())[0];
  let balance = await tradeFlowContract.methods.balanceOf(address).call();
  let numbers = (Number(web3.utils.fromWei(balance)) / UNIT_MILLION).toFixed(2);
  return BigNumber(numbers);
}
export async function apiGetMyBalance() {
  let address = (await web3.eth.getAccounts())[0];
  let balance = await tradeFlowContract.methods.balanceOf(address).call();
  return (Number(web3.utils.fromWei(balance)) / UNIT_MILLION).toFixed(2);
}
// 对应角色
export async function apiResolveRoleId() {
  return resolveRoleId();
}
// 获取融资信息 处理金额相关信息，上报合约以及存入数据库，所有的数据需要 * 1000000；获取数据展示，所有的数据需要 / 1000000
export async function apiGetFinanceRequests() {
  let result = await getFinanceRequests(tradeFlowContract);
  const MILLISECONDS_PER_DAY = 86400 * 1000;
  let nowDateTime = new Date().getTime();
  result = result.map(async item => {
    let deadlineDateTime = new Date(formatDate(new Date(parseInt(item.manufacturingStartDate) - (7 * MILLISECONDS_PER_DAY)))).getTime();
    let repayDateTime = new Date(parseInt(item.manufacturingStartDate) + (parseInt(item.length) * MILLISECONDS_PER_DAY));
    let row = {};
    // 基础字段
    row.id = item.id;
    row.supplierAddress = item.supplierAddress;
    row.supplier = item.supplier;
    row.description = item.description; // 目前此字段存储的手机号
    row.length = item.length;
    row.buyer = item.buyer;
    row.fundsSent = item.fundsSent;
    row.repaid = item.repaid;
    row.manufacturingStartDate = item.manufacturingStartDate; // 发货日期
    row.manufacturingStartDateStr = formatDate(new Date(parseInt(item.manufacturingStartDate))); // 发货日期
    row.purpose = item.purpose;
    row.blockNumber = item.blockNumber;
    row.apr = item.apr;
    row.aprStr = item.apr + "%";
    row.statusId = item.statusId;
    row.status = item.status;
    row.brandOwnerAddress = item.brandOwnerAddress;
    row.brandOwner = item.brandOwner;
    row.extraData = item.extraData; // 目前此字段存储对象：文件url，银行账号account、开户账号user、开户银行bank相关信息
    row.extraDataObj = JSON.parse(JSON.stringify(item.extraData)); // 目前此字段存储对象：文件url，银行账号、开户账号、开户银行相关信息
    row.fundsProvided = item.fundsProvided; // 已提供资金？不明确
    row.askAmount = item.askAmount; // 融资金额、应还款、应收款金额（*折扣后的）
    row.askAmountStr = (Number(item.askAmount) / UNIT_MILLION).toFixed(2); // 融资
    row.repayAmount = item.repayAmount; // 订单金额（总金额）
    row.repayAmountStr = (Number(item.repayAmount) / UNIT_MILLION).toFixed(2); // 订单金额
    row.fundingSum = item.fundingSum; // 已还金额
    row.fundingSumStr = (Number(item.fundingSum) / UNIT_MILLION).toFixed(2); // 已还金额
    // 附加字段
    // 还款、付款日期
    row.endDate = formatDate(repayDateTime);
    // 募资截止日期
    row.deadlineDate = formatDate(deadlineDateTime);
    // 募资进度
    let progress = Number(item.fundingSum) / Number(item.askAmount);
    row.investmentProgress = progress > 100 ? 100 + "%" : progress < 0 ? 0 : (progress.toFixed(2) * 100) + "%";
    // 还款方式
    // row.method = ;
    // row.days = ; // 格式化天数
    // 利息
    row.interest = ((Number(item.repayAmount) - Number(item.askAmount)) / UNIT_MILLION).toFixed(2);
    // 融资剩余天数
    row.remainingDays = nowDateTime > deadlineDateTime ? 0 : differenceInDays(deadlineDateTime, nowDateTime);
    // 还款剩余天数
    row.repayDays = differenceInDays(repayDateTime, nowDateTime);
    // 剩余募资金额（可投）
    row.toInvestAmount = (Number(item.askAmount - item.fundingSum) / UNIT_MILLION).toFixed(2);
    return row;
  });
  return Promise.all(result);
}
// 获取当前人融资信息
// 充值提现数据存入合约 0 Deposit 1 Withdraw
export async function exchangeClick(exchangeAmount, exchangeType) {
  let address = (await web3.eth.getAccounts())[0];
  return await tradeFlowContract.methods
    .createExchangeRequest(web3.utils.toWei(exchangeAmount), exchangeType)
    .send({ from: address });
}
// 获取充值记录
export async function apiGetExchangeRequests() {
  let result = await getExchangeRequests();
  return Promise.all(result);
}
// 审核充值提现通过结果 传入合约数据id
export async function apiApproveExchangeRequest(id) {
  let address = (await web3.eth.getAccounts())[0];
  await tradeFlowContract.methods
    .approveExchangeRequest(id)
    .send({ from: address });
}
// 审核充值提现拒绝结果 传入合约数据id
export async function apiRejectExchangeRequest(id, reason) {
  let address = (await web3.eth.getAccounts())[0];
  await tradeFlowContract.methods
    .rejectExchangeRequest(id, reason)
    .send({ from: address });
}
// 公共 end

// 核心企业 start
// 核心企业 end
// 供应商 start
// 获取供应商列表
export async function apiGetSupplierOverview() {
  return await getSupplierOverview();
}
// 供应商 end
// 投资人 start
// 投资人 end

// 格式化及工具函数
function formatDate(date, fmt) {
  if (fmt === undefined || fmt === null || fmt === "") {
    fmt = "yyyy-MM-dd";
  }
  return format(date, fmt);
}
